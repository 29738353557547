<template>
  <div class="loading-box" v-if="isProgress">
    <!-- <p class="loading-number">{{ sum }}%</p> -->

    <div class="ai-progress">
      <div
        class="loading-items"
        v-for="(item, index) in 50"
        :key="index"
        :style="{
          opacity:
            index * 2 < sum ? 0.2 + [(index + 1) / (sum * 50)] * sum : '0.2',
        }"
      ></div>
    </div>
    <div class="loading-txt">
      <!-- <p class=""></p> -->
      加载中
    </div>
  </div>
</template>

<script>
export default {
  name: "aiProgress",
  props: {
    isProgress: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: [String, Number],
      default: "",
    },
    cropsInfoMark: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sum: 0,
      timer: null,
    };
  },
  watch: {
    isProgress(newValue) {
      if (!newValue) return;
      this.timer = setInterval(this.addRandom, 1000);
    },
  },

  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    addRandom() {
      this.count = 0;
      if (this.sum >= 80) {
        this.sum = 100;
        clearInterval(this.timer);
        this.$router.push({
          name: "administer",
          params: { type: this.tabIndex, mark: this.cropsInfoMark },
        });
      }
      this.count++;
      let randomNum = Math.floor(Math.random() * 55) + 1;
      this.sum += randomNum;
    },
  },
};
</script>

<style lang="less" scoped>
.loading-box {
  display: flex;
  flex-direction: column;
  .loading-number {
    width: 828px;
    font-size: 30px;
    text-align: right;
    padding: 0 80px 0 0;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #3cabf5;
  }
  .ai-progress {
    width: 828px;
    height: 98px;
    border-radius: 5px;
    background: url("../../../assets/image/ai/progress/progress_bg.png") center
      center no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
    padding: 16px 0 0 0;
    .loading-items {
      width: 10px;
      height: 70%;
      background-color: #0ebeff;
      margin-right: 4px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .loading-txt {
    width: 200px;
    height: 60px;
    font-size: 48px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    margin: 0 auto;
    color: #3cabf5;
    display: flex;
    align-items: flex-end;
    &::after {
      content: "";
      // color: #fff;
      animation:hd 3s linear infinite backwards;

    }

  }
}
@keyframes hd {
  from {
    // box-shadow: none;
    content:'...'
  }
  25% {
    content:''
    // box-shadow: 4px 0 0 red;
  }
  50% {
    content:'.'
    // box-shadow: 4px 0 0 currentColor, 12px 0 0 currentColor;
  }
  70% {
    // box-shadow: 4px 0 0 currentColor, 12px 0 0 currentColor,
    content:'..'
    //   20px 0 0 currentColor;
  }
  100%{
    content:'...'

  }
}
@keyframes loading-rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>
